// frontend/src/components/PrivateRoute.js

import React from 'react';
import { Navigate } from 'react-router-dom';

/**
 * PrivateRoute コンポーネント
 * 
 * @param {object} props - コンポーネントのプロパティ
 * @param {boolean} props.isAuthenticated - ユーザーが認証されているかどうか
 * @param {React.ReactNode} props.children - 子コンポーネント
 * @returns {React.ReactNode} - 子コンポーネントまたはリダイレクト
 */
const PrivateRoute = ({ isAuthenticated, children }) => {
  return isAuthenticated ? children : <Navigate to="/login" />;
};

export default PrivateRoute;
