// frontend/src/components/Auth.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Auth.css';
import { auth } from '../firebase';
import { 
  createUserWithEmailAndPassword, 
  sendEmailVerification, 
  signInWithEmailAndPassword,
  onAuthStateChanged,
} from 'firebase/auth';
import Modal from './Modal'; // カスタムモーダルコンポーネントをインポート
import { useNavigate } from 'react-router-dom'; // useNavigate をインポート

const Auth = ({ setUid, mode }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLogin, setIsLogin] = useState(mode === 'login');
  const [passwordStrength, setPasswordStrength] = useState('');
  const [user, setUser] = useState(null);
  
  // モーダル管理の状態
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  const navigate = useNavigate(); // useNavigate フックを使用

  // パスワード強度チェック関数
  const isStrongPassword = (password) => {
    // パスワードは8文字以上で、大文字、小文字、数字を含む
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
    return regex.test(password);
  };

  // パスワード入力時の強度チェック
  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);

    if (isStrongPassword(newPassword)) {
      setPasswordStrength('強いパスワードです。');
    } else {
      setPasswordStrength('パスワードは8文字以上で、大文字、小文字、数字を含めてください。');
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });

    return () => unsubscribe();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // パスワードの強度チェック
    if (!isStrongPassword(password)) {
      setModalMessage('パスワードは8文字以上で、大文字、小文字、数字を含めてください。');
      setIsModalOpen(true);
      return;
    }

    try {
      if (isLogin) {
        // ログイン
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        
        if (!userCredential.user.emailVerified) {
          await sendEmailVerification(userCredential.user);
          setModalMessage('メールアドレスが確認されていません。確認メールを再送信しました。メールボックスを確認してください。');
          setIsModalOpen(true);
          return;
        }

        const idToken = await userCredential.user.getIdToken();

        // トークンをローカルストレージに保存
        localStorage.setItem('authToken', idToken);

        // バックエンドにログインリクエスト
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}/auth/login`,
            {
              idToken: idToken,
            }
          );

          if (response.data && response.data.uid) {
            setUid(response.data.uid);
            navigate('/chat'); // ログイン後にチャット画面に遷移
          } else {
            throw new Error('Invalid response from server');
          }
        } catch (error) {
          console.error(
            'バックエンドのログインエラー:',
            error.response ? error.response.data : error.message
          );
          setModalMessage('バックエンドのログイン中にエラーが発生しました。');
          setIsModalOpen(true);
        }

      } else {
        // ユーザー登録
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        await sendEmailVerification(userCredential.user);
        setModalMessage('確認メールを送信しました。メールボックスを確認してください。登録を完了するには、メール内のリンクをクリックしてください。');
        setIsModalOpen(true);
        setIsLogin(true);
      }
    } catch (error) {
      console.error('認証エラー:', error.response?.data || error.message);
      let errorMessage = '認証に失敗しました。';

      if (error.code === 'auth/email-already-in-use') {
        errorMessage = 'このメールアドレスは既に使用されています。';
      } else if (error.code === 'auth/invalid-email') {
        errorMessage = '無効なメールアドレスです。';
      } else if (error.code === 'auth/weak-password') {
        errorMessage = 'パスワードが弱すぎます。8文字以上で、大文字、小文字、数字を含めてください。';
      } else if (error.code === 'auth/user-not-found') {
        errorMessage = 'ユーザーが見つかりません。';
      } else if (error.code === 'auth/wrong-password') {
        errorMessage = 'パスワードが間違っています。';
      } else if (error.message.includes('Network Error')) {
        errorMessage = 'バックエンドサーバーに接続できません。サーバーが稼働しているか確認してください。';
      }

      setModalMessage(errorMessage);
      setIsModalOpen(true);
    }
  };

  return (
    <div className="auth-container">
      <h2>{isLogin ? 'ログイン' : 'ユーザー登録'}</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="メールアドレス"
          required
        />
        <input
          type="password"
          value={password}
          onChange={handlePasswordChange}
          placeholder="パスワード"
          required
        />
        <p className="password-strength">{passwordStrength}</p>
        <button type="submit">{isLogin ? 'ログイン' : '登録'}</button>
      </form>
      <p onClick={() => setIsLogin(!isLogin)} style={{ cursor: 'pointer' }}>
        {isLogin ? 'アカウントをお持ちでないですか？登録' : '既にアカウントをお持ちですか？ログイン'}
      </p>

      {/* メール確認メッセージの表示（オプション） */}
      {user && !user.emailVerified && (
        <div className="email-verification">
          <p>メールアドレスが確認されていません。確認メールを再送信しますか？</p>
          <button
            onClick={async () => {
              await sendEmailVerification(user);
              setModalMessage('確認メールを再送信しました。メールボックスを確認してください。');
              setIsModalOpen(true);
            }}
          >
            確認メールを再送信
          </button>
        </div>
      )}

      {/* カスタムモーダル */}
      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        message={modalMessage}
      />
    </div>
  );
};

export default Auth;
