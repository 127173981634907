// frontend/src/components/Contact.js
import React from 'react';
import './Contact.css';

const Contact = () => {
  return (
    <div className="contact-container">
      <h2>お問い合わせ</h2>
      <form>
        <input type="text" placeholder="お名前" required />
        <input type="email" placeholder="メールアドレス" required />
        <textarea placeholder="お問い合わせ内容" required></textarea>
        <button type="submit">送信</button>
      </form>
    </div>
  );
};

export default Contact;
