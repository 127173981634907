// frontend/src/components/Navbar.js
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import { signOut } from 'firebase/auth';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import './Navbar.css';

const Navbar = ({ isAuthenticated }) => {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  // const user = auth.currentUser; // 削除: 'user' は未使用

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      localStorage.removeItem('authToken'); // ログアウト時にトークンを削除
      navigate('/login'); // ログアウト後、ログインページにリダイレクト
    } catch (error) {
      console.error('ログアウトエラー:', error);
      // 必要に応じてエラーハンドリング（例: モーダル表示）
    }
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <nav className="navbar">
      <h1 className="navbar-title">アプリ名</h1>
      <div className="navbar-menu-icon" onClick={toggleMenu}>
        {menuOpen ? <CloseIcon /> : <MenuIcon />}
      </div>
      <ul className={`navbar-links ${menuOpen ? 'active' : ''}`}>
        {!isAuthenticated && (
          <>
            <li>
              <Link to="/login" onClick={() => setMenuOpen(false)}>ログイン</Link>
            </li>
            <li>
              <Link to="/register" onClick={() => setMenuOpen(false)}>新規登録</Link>
            </li>
            <li>
              <Link to="/contact" onClick={() => setMenuOpen(false)}>お問い合わせ</Link>
            </li>
          </>
        )}
        {isAuthenticated && (
          <>
            <li>
              <Link to="/faq" onClick={() => setMenuOpen(false)}>よくある質問</Link>
            </li>
            <li>
              <span className="logout-text" onClick={() => { handleSignOut(); setMenuOpen(false); }}>ログアウト</span>
            </li>
          </>
        )}
      </ul>
    </nav>
  );
};

export default Navbar;
