// frontend/src/components/FAQ.js
import React from 'react';
import './FAQ.css';

const FAQ = () => {
  return (
    <div className="faq-container">
      <h2>よくある質問</h2>
      <div className="faq-item">
        <h3>質問1: ...</h3>
        <p>回答1: ...</p>
      </div>
      <div className="faq-item">
        <h3>質問2: ...</h3>
        <p>回答2: ...</p>
      </div>
      {/* 追加のFAQ項目 */}
    </div>
  );
};

export default FAQ;
