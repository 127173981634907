// frontend/src/components/ChatWindow.js

import React, { useEffect, useState, useRef, useCallback } from 'react';
import axios from 'axios';
import { FaArrowUp, FaMicrophone } from 'react-icons/fa';
import './ChatWindow.css';
import { auth } from '../firebase'; // Firebase の auth をインポート
import MessageBubble from './Message'; // MessageBubbleをインポート

const ChatWindow = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [isRecording, setIsRecording] = useState(false);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);

  // 認証トークンの取得（Firebase ID トークン）
  const getAuthToken = async () => {
    const user = auth.currentUser;
    if (user) {
      return await user.getIdToken();
    }
    return null;
  };

  // チャット履歴の取得
  const fetchChatHistory = useCallback(async () => {
    try {
      const token = await getAuthToken();
      if (!token) {
        throw new Error('ユーザーが認証されていません。');
      }

      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/chat/history`,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
          withCredentials: true,
        }
      );
      setMessages(response.data.messages);
    } catch (error) {
      console.error('チャット履歴取得エラー:', error.response ? error.response.data : error.message);
    }
  }, []);

  useEffect(() => {
    fetchChatHistory();
  }, [fetchChatHistory]);

  const handleSend = async () => {
    if (!input.trim()) return;

    const messageToSend = input;
    setInput(''); // 送信前に入力欄をクリア

    const newMessage = {
      id: Date.now(), // 一意のIDを生成（バックエンドからIDを取得する場合は調整）
      text: messageToSend,
      sender: 'user',
      timestamp: new Date(),
    };

    setMessages(prevMessages => [...prevMessages, newMessage]);

    try {
      const token = await getAuthToken();
      if (!token) {
        throw new Error('ユーザーが認証されていません。');
      }

      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/chat`,
        { message: messageToSend },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
          withCredentials: true,
        }
      );

      const botMessage = {
        id: Date.now() + 1, // 一意のIDを生成
        text: response.data.message,
        sender: 'bot',
        timestamp: new Date(),
      };

      setMessages(prevMessages => [...prevMessages, botMessage]);
    } catch (error) {
      console.error('メッセージ送信エラー:', error.response ? error.response.data : error.message);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSend();
    }
  };

  // 音声録音ハンドラー
  const handleMicClick = () => {
    if (isRecording) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    } else {
      navigator.mediaDevices.getUserMedia({ audio: true })
        .then(stream => {
          mediaRecorderRef.current = new MediaRecorder(stream);
          mediaRecorderRef.current.start();
          setIsRecording(true);

          mediaRecorderRef.current.ondataavailable = event => {
            audioChunksRef.current.push(event.data);
          };

          mediaRecorderRef.current.onstop = async () => {
            const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
            audioChunksRef.current = [];

            try {
              const token = await getAuthToken();
              if (!token) {
                throw new Error('ユーザーが認証されていません。');
              }

              const formData = new FormData();
              formData.append('audio', audioBlob, 'recording.wav');

              const response = await axios.post(
                `${process.env.REACT_APP_API_BASE_URL}/speech/transcribe`, // エンドポイントを修正
                formData,
                {
                  headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${token}`,
                  },
                  withCredentials: true,
                }
              );

              const botMessage = {
                id: Date.now() + 2, // 一意のIDを生成
                text: response.data.message,
                sender: 'bot',
                timestamp: new Date(),
              };
              setMessages(prevMessages => [...prevMessages, botMessage]);
            } catch (error) {
              console.error('音声アップロードエラー:', error.response ? error.response.data : error.message);
            }
          };
        })
        .catch(error => {
          console.error('マイクアクセスエラー:', error.message);
        });
    }
  };

  return (
    <div className="chat-window">
      <div className="messages">
        {messages.map((msg) => (
          <MessageBubble
            key={msg.id}
            message={msg}
            isUser={msg.sender === 'user'}
          />
        ))}
      </div>
      <div className="input-area">
        <input
          type="text"
          placeholder="メッセージを入力..."
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyPress={handleKeyPress}
        />
        <FaArrowUp
          className="icon send-icon"
          title="メッセージ送信"
          onClick={handleSend}
        />
        <FaMicrophone
          className={`icon mic-icon ${isRecording ? 'recording' : ''}`}
          title={isRecording ? '録音停止' : '音声入力'}
          onClick={handleMicClick}
        />
      </div>
    </div>
  );
};

export default ChatWindow;
