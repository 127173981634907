// frontend/src/components/Message.js

import React, { useState } from 'react';
import axios from 'axios';
import './Message.css';
import { auth } from '../firebase'; // 追加

const MessageBubble = ({ message, isUser }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [popupContent, setPopupContent] = useState(null);
  const [feedbackType, setFeedbackType] = useState(null); // フィードバックの種類を追跡

  // 認証トークンの取得（Firebase ID トークン）
  const getAuthToken = async () => {
    const user = auth.currentUser;
    if (user) {
      return await user.getIdToken();
    }
    return null;
  };

  const handleFeedbackClick = async (type) => {
    setFeedbackType(type);
    try {
      const token = await getAuthToken();
      if (!token) {
        throw new Error('ユーザーが認証されていません。');
      }

      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/feedback/${message.id}?type=${type}`,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
          withCredentials: true,
        }
      );
      setPopupContent(response.data);
      setShowPopup(true);
    } catch (error) {
      console.error('フィードバックの取得に失敗しました', error.response ? error.response.data : error.message);
      setPopupContent({ error: 'フィードバックの取得に失敗しました。' });
      setShowPopup(true);
    }
  };

  return (
    <div className={`message ${isUser ? 'user' : 'bot'}`}>
      <p>{message.text}</p>
      <div className="feedback-icons">
        <span onClick={() => handleFeedbackClick('pronunciation')} title="発音フィードバック">🎙️</span>
        <span onClick={() => handleFeedbackClick('grammar')} title="文法フィードバック">📖</span>
      </div>
      {showPopup && (
        <div className="feedback-popup">
          <button onClick={() => setShowPopup(false)}>閉じる</button>
          {popupContent.error ? (
            <p>{popupContent.error}</p>
          ) : feedbackType === 'pronunciation' ? (
            <div>
              <p>発音スコア: {popupContent.score}</p>
              <p>アドバイス: {popupContent.advice}</p>
            </div>
          ) : (
            <div>
              <p>修正点: {popupContent.correction}</p>
              <p>アドバイス: {popupContent.advice}</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default MessageBubble;
