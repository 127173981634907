// frontend/src/App.js

import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Auth from './components/Auth';
import ChatWindow from './components/ChatWindow';
import Contact from './components/Contact'; // お問い合わせページ
import FAQ from './components/FAQ'; // FAQページ
import PrivateRoute from './components/PrivateRoute'; // PrivateRouteをインポート
import { auth } from './firebase';
import { onAuthStateChanged } from 'firebase/auth';
import axios from 'axios'; // axios をインポート

const App = () => {
  const [uid, setUid] = useState(null);
  const [loading, setLoading] = useState(true); // 認証状態の確認中かどうか

  useEffect(() => {
    console.log('REACT_APP_API_BASE_URL:', process.env.REACT_APP_API_BASE_URL); // 環境変数の値をログ出力

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user && user.emailVerified) {
        const idToken = await user.getIdToken();
        localStorage.setItem('authToken', idToken);

        // バックエンドにログインリクエスト
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}/auth/login`, // 環境変数を使用
            {
              idToken: idToken,
            }
          );

          if (response.data && response.data.uid) {
            setUid(response.data.uid);
          } else {
            throw new Error('Invalid response from server');
          }
        } catch (error) {
          console.error(
            'バックエンドのログインエラー:',
            error.response ? error.response.data : error.message
          );
        }
      } else {
        setUid(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return <div>Loading...</div>; // 認証状態確認中のローディング表示
  }

  return (
    <Router>
      <Navbar isAuthenticated={!!uid} />
      <Routes>
        <Route path="/login" element={<Auth setUid={setUid} mode="login" />} />
        <Route path="/register" element={<Auth setUid={setUid} mode="register" />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/faq" element={<FAQ />} />
        <Route
          path="/chat"
          element={
            <PrivateRoute isAuthenticated={!!uid}>
              <ChatWindow />
            </PrivateRoute>
          }
        />
        <Route path="*" element={<Auth setUid={setUid} mode="login" />} /> {/* デフォルトルート */}
      </Routes>
    </Router>
  );
};

export default App;
