// frontend/src/components/Modal.js
import React from 'react';
import { Modal as MuiModal, Box, Typography, Button } from '@mui/material';
import './Modal.css';

/**
 * モーダルのスタイル
 */
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #007bff',
  boxShadow: 24,
  p: 4,
  borderRadius: '10px',
};

/**
 * Modal コンポーネント
 * 
 * @param {object} props - コンポーネントのプロパティ
 * @param {boolean} props.open - モーダルの開閉状態
 * @param {function} props.onClose - モーダルを閉じる関数
 * @param {string} props.message - モーダルに表示するメッセージ
 */
const Modal = ({ open, onClose, message }) => {
  return (
    <MuiModal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-description" sx={{ mt: 2, mb: 2 }}>
          {message}
        </Typography>
        <Button variant="contained" onClick={onClose}>
          閉じる
        </Button>
      </Box>
    </MuiModal>
  );
};

export default Modal;
